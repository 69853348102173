<section class="practice-area pt-50 pb-70">
    <div class="container">

       
            <h2 class="section-category-title">
              {{categoryName}}
            </h2>
            <br>
        <!--<div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Our Legal Practices Areas</h2>
        </div>-->

        <div class="row  border-b">
            <ng-container  *ngIf="TopicsData.TobicsListFirstRow && TopicsData.TobicsListFirstRow.length > 0"  >
            <div class="col-sm-6 col-lg-8">
                <a   routerLink="/tobic/{{ TopicsData.TobicsListFirstRow[0]?.SubjectTitle }}/{{ TopicsData.TobicsListFirstRow[0]?.SubjectId }}"  >
                <div class="practice-item">
                    <div  >
                        
                        <img  class="img-fluid"  style="width: 100%;   max-height: 27rem;" [src]="prefixUrl+TopicsData?.TobicsListFirstRow[0]?.ImagePath"  alt="Shape"   >
                    </div>
                    <h3> {{TopicsData.TobicsListFirstRow[0]?.SubjectTitle}}</h3>
                    <div>  <span class="card-article-writer"> {{TopicsData.TobicsListFirstRow[0]?.AuthorName}}   </span></div>
                   
                    
                </div>
            </a>
            </div>

            <div class="col-sm-6 col-lg-4" *ngIf="TopicsData.TobicsListFirstRow.length >  1">
                <a  routerLink="/tobic/{{ TopicsData.TobicsListFirstRow[1]?.SubjectTitle }}/{{ TopicsData.TobicsListFirstRow[1]?.SubjectId }}">

                <div class="practice-item">
                    <div  >
                        <img  class="news-cover-img" [src]="prefixUrl+TopicsData.TobicsListFirstRow[1]?.ImagePath"  alt="Shape" width= "100%;"   height= "238px;">
                    </div>
                    <h3 class="news-title" style="  line-height: 32px;  "> {{TopicsData.TobicsListFirstRow[1]?.SubjectTitle}}</h3>
                    <div>  <span class="card-article-writer"> {{TopicsData.TobicsListFirstRow[1]?.AuthorName}}   </span></div>
                </div>
                </a>
            </div>
            

        </ng-container>


 
        </div>
        <div class="row">
            <ng-container *ngFor="let subItem of TopicsData.TobicsListOtherRows">

            <div class="col-sm-6 col-lg-4"  style="    margin-bottom: 28px;">
                <a   routerLink="/tobic/{{ subItem.SubjectTitle }}/{{ subItem.SubjectId }}" >

                <div class="practice-item">
                    <div  >
                        <img class="news-cover-img" [src]="prefixUrl+subItem?.ImagePath"   alt="Shape"  width="238px;">

                    </div>
                    <h5 class="news-title" style="  line-height: 32px;  ">  {{subItem.SubjectTitle}}</h5>
                    <div>  <span class="card-article-writer"> {{subItem?.AuthorName}}   </span></div>
                   
                </div>
                </a>
            </div>
            
    </ng-container>
        </div>
    </div>
</section>