<div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>   حول 4dpal  </h2>

                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>حول المؤسسة</li>
                </ul>

               
            </div>
        </div>
    </div>
</div>
<div class="help-area help-area-two help-area-four pb-70">
    <h3>
        وكالة 4D PAL هي احدى مؤسسات شركة البعد الرابع للانتاج وهي وكالة متخصصة بالاعلام الرقمي وتسعى لان تتحول من وكالة فلسطينية الى وكالة عالمية .

    </h3>
    <!-- <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    محتوى 
                </div>
            </div>
        </div>
    </div> -->
</div>

<!--
<div class="help-area help-area-two help-area-four pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/5.jpg" alt="Help">
                </div>
            </div>
            <div class="
            col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>We Provide Professional Attorneys to Assist You</h2>
                        <p>We are qualified for the success of our client & as a professional law firm. So these professional attorneys are still here at your side with kindness, integrity, and loyalty to help with the worst moments of your life.</p>
                        <p>We are qualified for the success of our client & as a professional law firm. So these professional attorneys are still here at your side with kindness.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li>
                                    <i class="flaticon-checkmark"></i> Browse Our Website</li>
                                <li><i class="flaticon-checkmark"></i> Choose Services</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Quick Reply</li>
                                <li><i class="flaticon-checkmark"></i> Best Performances</li>
                            </ul>
                        </div>

                        <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div>

                        <h3>Barrister Babatunde Smithi</h3>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center help-wrap">
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Our Attorneys Always Provide The Excellent Service</h2>
                        <p>Teams of  Intelligent & Professional Attorneys of Lyzo are always prepared to make you triumph. We always try our best to make our clients happy.</p>

                        <div class="help-inner-left">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> On Time Response</li>
                                <li><i class="flaticon-checkmark"></i> Legal Way</li>
                            </ul>
                        </div>

                        <div class="help-inner-right">
                            <ul>
                                <li><i class="flaticon-checkmark"></i> Legal Services</li>
                                <li><i class="flaticon-checkmark"></i> Honest Work</li>
                            </ul>
                        </div>

                        <a class="cmn-btn" routerLink="/practice">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help">
                </div>
            </div>
        </div>

        <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape">
        </div>
    </div>
</div>

<section class="expertise-area expertise-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>OUR EXPERTISE</span>
            <h2>Law Firm Devoted To Our Clients</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Experience</h3>
                            <p>Lyzo teams are exceptionally skilled, acknowledge their client’s condition & help them to achieve their target.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorney</h3>
                            <p>Our attorneys are innovative and knowledgeable and help clients pursue a vast number of solutions to succeed.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Proces</h3>
                            <p>We maintain all valid papers and paperwork and continue to meet our destination legally.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Low Cost</h3>
                            <p>Team of Lyzo are exceptionally skilled, realize their client’s condition, help them to achieve their motive.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-time"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Good Performance</h3>
                            <p>Our attorneys are creative and knowledgeable and support clients by finding numerous solutions to win.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-conversation"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Quick Service</h3>
                            <p>We manage all valid papers and paperwork and continue to enter our path lawfully.</p>
                        </li>
                    </ul>
               </div>
            </div>
        </div>
    </div>
</section>

<div class="about-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-video-wrap">
                        <div class="about-video">
                            <img src="assets/img/about/2.jpg" alt="About">
                        </div>

                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                    </div>

                    <div class="about-content">
                        <h2>Our skills makes us famous</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-information">
                        <h2><span>21 feb 1998,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>10 March 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>9 Nov 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>25 Jan 2010,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->