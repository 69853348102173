import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var FB: any;
@Component({
  selector: 'app-news-share',
 
  templateUrl: './news-share.component.html',
  styleUrl: './news-share.component.scss'
})
export class NewsShareComponent implements OnChanges {
  
  readonly prefixUrl: string = environment.apiUrl + "/Image/view/";
        // The URL to share
  @Input() title: string = '4dpal';        // The title of the post
  @Input() description: string = '4dpal';  // The description of the post
  @Input() imageUrl: string = 'https://4dpal.com/assets/img/logo/4dmedia_logo_1473.png';     // The image URL for the post
  url:string= window.location.href ;
  facebookShareUrl: string = '';
  constructor(private metaService: Meta ) {}
  ngOnChanges(changes: SimpleChanges) {
    // Check if any of the input properties have changed
    if (changes['title'] || changes['description'] || changes['imageUrl']  ) {
      this.updateMetaTags();
    }
  }

  updateMetaTags() {
     
 
   // const imageUrl = this.prefixUrl + this.newsData?.ImagePath;
   console.log( this.title);
   console.log( this.description);
   console.log( this.imageUrl);
   console.log( this.url);
      this.metaService.updateTag({ name: 'description', content: this.title });
      this.metaService.updateTag({ property: 'og:title', content: this.title });
      this.metaService.updateTag({ property: 'og:description', content: this.description });
      this.metaService.updateTag({ property: 'og:image', content: this.imageUrl});
      this.metaService.updateTag({ property: 'og:url', content: this.url });
      this.metaService.updateTag({ property: 'og:type', content: 'website' }); // Set og:type
      this.metaService.updateTag({ property: 'fb:app_id', content: '1242360266780550' }); // Set fb:app_id
  
  }
  shareOnFacebook() {
    // Function to share the content on Facebook
    if (typeof FB !== 'undefined') {
      FB.ui({
        method: 'share',
        href: this.url,
        quote: this.title,
        hashtag: '#example',
        picture: this.imageUrl,
        description: this.description
      }, (response: any) => {
        if (response && !response.error_message) {
          console.log('Sharing completed successfully.');
        } else {
          console.error('Error while sharing:', response.error_message);
        }
      });
    } else {
      console.error('Facebook SDK is not loaded.');
    }
  }
  
}
 