import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Video } from 'src/app/_models/Video';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { DatePipe } from '@angular/common'
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  readonly prefixUrl:string= environment.apiUrl+"/Image/view/";


  videosData: Array<Video>=[];
  constructor( private titleService: Title,private mainService: MainService , private sanitizer: DomSanitizer, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.titleService.setTitle("فيديوهات قناتنا"+" | "+"4Dpal");
    this.mainService.getVideos("0").subscribe(

      data=>{
      
       this.videosData= JSON.parse(JSON.stringify(data));
        
    
      },error =>{
        console.log( "getLatestNews",error);
      }
    );
  }

}
