import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/_models/News';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { Category } from 'src/app/_models/Category';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tobics',
  templateUrl: './tobics.component.html',
  styleUrls: ['./tobics.component.scss']
})
export class TobicsComponent implements OnInit {
 
 
TopicsData:any;

public firstTobicData:any;
readonly prefixUrl:string= environment.apiUrl+"/Image/view/";


  constructor( private titleService: Title,private mainService: MainService ) {
 
    
   }

  ngOnInit(): void {
 
    this.selectTopic("1");

  }

  selectTopic(tabId: string) {
    this.TopicsData=null;
    this.firstTobicData= null;
    this.mainService.getAllTobics(Number.parseInt(tabId)).subscribe(
      data=>{
     
        let topicsData=  JSON.parse(JSON.stringify(data));
        
     
        if(tabId != "1"){
          this.firstTobicData=topicsData[0];
          this.TopicsData= topicsData.slice(1,4);
        }else{
          this.TopicsData= topicsData;
        }
      
        
     
         
      },error =>{
        console.log( "getLatestNews",error);
      }
    );
  }

}
