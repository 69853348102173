import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedDataService } from 'src/app/_services/shared-data.service';

@Component({
  selector: 'app-weather-conditions',
 
  templateUrl: './weather-conditions.component.html',
  styleUrl: './weather-conditions.component.scss'
})
export class WeatherConditionsComponent implements OnInit  {
 
 
  currentDate: any;
  weatherDate$: Observable<any>;
  weatherData:any=[];
  weatherDegree: number; 
  maxDegree: number; 
  minDegree: number; 
  WeatherStatus:string;
  constructor(   private sharedDataService: SharedDataService ) { }
  ngOnInit(): void {
    var arabicDate=  new Date().toLocaleDateString('ar-PS', {day: 'numeric', month: 'short',weekday: 'long',year : 'numeric'});
    this.currentDate = arabicDate;

    this.fetchWeatherDate();

    
  }

 
  
 

  fetchWeatherDate(): void {
     this.sharedDataService.weatherDate$.subscribe(
      (data) => {
        // Handle the emitted data here
        let dataList = data;
        if(dataList){
          this.weatherData=dataList;
          
          this.getDegree('jerusalem');
        }
        
       },
      (error) => {
        // Handle any errors here
        console.error('Error fetching categories:', error);
      }
    )

  }
 
  onCityChange(event: Event):void {
    
    const selectElement = event.target as HTMLSelectElement;
    const selectedCity = selectElement.value;
 
    this.getDegree(selectedCity);
     
    
  }
  getDegree(selectedCity:string){
    let degree= this.weatherData.find(a=>a.CityName ==  selectedCity);
    if(degree){
      this.weatherDegree = degree ?  degree.Temperature : 0;
      this.maxDegree = degree ?  degree.MaxTemperature : 0;
      this.minDegree = degree ?  degree.MinTemperature : 0;
       let status= degree ?  degree.WeatherStatus : 0;
      if(status =="Clouds"){
        this.WeatherStatus="غائم";
      }else  if(status =="Snow"){
        this.WeatherStatus="ثلج";
      }else if(status=="Rain"){
        this.WeatherStatus="مطر";
      }else{
        this.WeatherStatus="صاف";
      }
    }
  }
}
