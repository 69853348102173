import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Video } from 'src/app/_models/Video';
import { MainService } from 'src/app/_services/main.service';
import { Buffer } from 'buffer';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {
  public videoId:string;
  public videoDate:Video;
  public videoLink:string = '';
  videosData: Array<Video>=[];
  readonly prefixUrl:string= environment.apiUrl+"/Image/view/";


  constructor( private titleService: Title,private activatedRoute: ActivatedRoute, private mainService: MainService , private sanitizer: DomSanitizer ) {
    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {  
        this.videoId= params['id'];
        this.mainService.getVideoById( this.videoId).subscribe(

          data=>{
          
           this.videoDate= JSON.parse(JSON.stringify(data));
           this.titleService.setTitle(this.videoDate.VideoTitle+" | "+"4Dpal");
             let linkCode = this.videoDate.VideoURL.split("=")[1];
         
         this.videoLink ="https://www.youtube.com/embed/"+ linkCode + "?autoplay=0";;
     
            
          },error =>{
            console.log( "getLatestNews",error);
          }
        );
      });
   


    this.mainService.getVideos("0").subscribe(

      data=>{
      
      let videosData= JSON.parse(JSON.stringify(data));
 
      
        this.videosData= videosData.filter(a=>a.VideoId != this.videoId);
    
      },error =>{
        console.log(error);
      }
    );
      
  }

}
