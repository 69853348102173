import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CategoryNews } from '../_models/Category';
import { News } from '../_models/News';
import { Buffer } from 'buffer';
import { DomSanitizer } from '@angular/platform-browser';
import { Video } from '../_models/Video';
import { LiveVideo } from '../_models/LiveVideo';
import { ImportantNews } from '../_models/ImportantNews';
@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getHomeNews() : Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/News/getNews');
  }


  getActiveCategories (): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Category/getActiveCategories');
  }

  getNewsALlData() : Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/News/getAllNews');
  }


  getNewsById(id: string) : Observable<News>{
    return this.http.get<any>(environment.apiUrl + '/News/'+id);
 }

 getNewsWithRelatedById(id: string) : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/News/getNewsWithRelated/'+id);
}
 getAllNewsByCategoryId(id:string,limit: string): Observable<News>{
  return this.http.get<News>(environment.apiUrl + '/News/getAllNewsByCategoryId/'+id+'/'+limit);
}

getAllCategoryNews(id:string,page:string,limit: string): Observable<any>{
  return this.http.get<News>(environment.apiUrl + '/News/getAllCategoryNews/'+id+'/'+page+'/'+limit);
}

getAllCategoryNewsData(): Observable<any> {
  return this.http.get<any[]>(environment.apiUrl + '/Category/getAllCategory').pipe(
    map((data1) => {
      // Manipulate or process data1 if needed
      return data1;
    }),
    // Calling the second API after processing the first
    map((data1) => {
      return this.http.get<any[]>(environment.apiUrl + '/News/getAllNews').pipe(
        map((data2) => {
          // Manipulate or process data2 if needed
          return [data1, data2];
        })
      );
    })
  );
}

getNewsByCategoryId(parm: any){
  
  let dateReturn: any = [ ];
  for (let i = 0; i < parm.length; i++) {
   this.getAllNewsByCategoryId(parm[i].CategoryId,"1").subscribe((data)=>{
  var newsData= JSON.parse(JSON.stringify(data));
 
  if(newsData != null){
    newsData.forEach(
      x=>{
       // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
       if(x.ImagePath  != null){
        x.ImageSrc =    environment.apiUrl + `/Image/${x.ImagePath}`;//this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,`+ Buffer.from(x.ImageData).toString('base64'));

       }else{
        x.ImageSrc ="assets/img/4dlogo.png";
       }

      // x.ImageSrc= Buffer.from(x.ImageData).toString('base64');
       }
    );
    var obj = new CategoryNews();
    obj.CategoryId= parm[i].CategoryId;
    obj.CategoryTitle= parm[i].CategoryTitle;
    obj.NewsList=newsData;
    obj.NewsListFirstRow=newsData.splice(0,3);
    obj.NewsListOtherRows=newsData;
    dateReturn.push(obj);
   }
 });

  }
 

   return dateReturn;
}

 
//findAllNews getLatestNews

getLatestNews(): Observable<any[]> {
  return this.http.get<any[]>(environment.apiUrl + '/News/GetLatestNews').pipe(
    catchError(this.handleError)
  );
}

private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
};
getVideos (limit:string): Observable<any> {
  return this.http.get<any>(environment.apiUrl + '/Video/GetLatestVideos/'+limit);
}
getVideoById(id: string) : Observable<Video>{
  return this.http.get<any>(environment.apiUrl + '/Video/GetVideoById/'+id);
}

getLiveVideo() : Observable<LiveVideo>{
  return this.http.get<any>(environment.apiUrl + '/LiveVideo/GetActiveLiveVideo');
}


getImportantNews() : Observable<ImportantNews>{
  return this.http.get<any>(environment.apiUrl + '/ImportantNews/PublishedNews');
}
getAllTobics(id=1,limit=6) : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/Subjects/getAllPublishedData/'+id+'/'+limit);
}
getAllTobicsByAuthorId(id) : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/Authors/getAllAuthorTobics/'+id);
}

getTobicById(id: string) : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/Subjects/getSubjectDataAndRelatedById/'+id);
}

GetAllContactInfo() : Observable<ImportantNews>{
  return this.http.get<any>(environment.apiUrl + '/ContactInfo/GetAllContactInfo');
}

GetAllAds() : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/Ads/GetAllAds');

}
GetAdsByOrder(order:string) : Observable<any>{
  return this.http.get<any>(environment.apiUrl + '/Ads/GetAdsByOrder/'+order);

}

//fetchBothData(): Observable<any[]> {
  //return forkJoin([this.getCategoryALlData(), this.getData1AndThenData2()]);
//}
}
