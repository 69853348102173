import {
 
  AfterViewInit,
    Component,
    ElementRef,
 
    OnInit,
    ViewChild,
} from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
 
import { forkJoin } from "rxjs";
import { Category, CategoryNews } from "src/app/_models/Category";
import { News } from "src/app/_models/News";
import { MainService } from "src/app/_services/main.service";
import { Buffer } from 'buffer';
import { Video } from "src/app/_models/Video";
 import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment.prod";
 
  
 
  
 
 

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
    providers: [NgbCarouselConfig]

})

export class HomeOneComponent implements OnInit  {
  

  breakingNewsItems: any;
  currentIndex = 0;
  public categoriesList: Array<Category> =new Array<Category>();
  categoryListSource: Array<string> = [];
  //CategoryNewsData: Array<CategoryNews> =new Array<CategoryNews>();
  CategoryNewsData:any;
  advsTwo:any;
  advsThree:any;
   latestNews:any;
   sliderNews: any
 homeNews:any;
 companyData:any;
 
 videosData: Array<Video>=[];
 public liveLink:string= '';
 public firstVideoLink:any;
 //firstVideoName:string="";
 dataFetchingInProgress: boolean = true;
 readonly prefixUrl:string= environment.apiUrl+"/Image/view/";
 readonly adsPrefixUrl:string= environment.apiUrl+"/Image/a/";
    constructor( private titleService: Title, private mainService: MainService , private sanitizer: DomSanitizer, config: NgbCarouselConfig) {
    	config.interval = 6000;
		config.wrap = true;
		config.keyboard = false;
		config.pauseOnHover = true;
    config.showNavigationArrows = true;
		config.showNavigationIndicators = true;
    
    }
   

    async ngOnInit() {
      this.titleService.setTitle( "الرئيسية"+" | "+"4Dpal");
      // this.mainService.getHomeNews().subscribe((data)=>{
      //   this.CategoryNewsData= data?.Categories;
      //   this.latestNews =data?.LatestNews;
      //       this.latestNews.forEach(x=>{
      //     let categoryName=  this.CategoryNewsData.find(a=>a.CategoryId ==x.CategoryId)?.CategoryTitle;
      //     x.CategoryName= categoryName;
      //   });
      //   this.sliderNews=data?.SliderNews;
      // });
      // this.mainService.getLiveVideo().subscribe((data)=>{
      //   this.liveLink = `https://www.youtube.com/embed/${data.LiveVideoUrl.split("=")[1]}?autoplay=1`;
      // });
      // this.mainService.getVideos("6").subscribe((data)=>{
      //   this.firstVideoLink = data[0];
      //   this.videosData = data?.splice(0, 4);
      // });
      // this.mainService.GetAdsByOrder(2).subscribe((data)=>{
      //   this.advsTwo= data;
      // });
      // this.mainService.GetAdsByOrder(2).subscribe((data)=>{
      //   this.advsThree= data;
      // });
       
       
      try {
        // Fetching all data concurrently using Promise.all()
        const [homeCategoryNews, videosData, advsData, ] = await Promise.all([
          this.mainService.getHomeNews().toPromise(),
          this.mainService.getVideos("6").toPromise(),
          this.mainService.GetAdsByOrder("2,3").toPromise(),
           
        ]);
      
        // Process Category News Data
        this.CategoryNewsData = homeCategoryNews?.Categories || [];
        this.latestNews = homeCategoryNews?.LatestNews || [];
        
        // Map Category Titles to Latest News
        const categoryMap = new Map<number, string>(
          this.CategoryNewsData.map(category => [category.CategoryId, category.CategoryTitle])
        );
      
        this.latestNews.forEach(news => {
          news.CategoryName = categoryMap.get(news.CategoryId) || '';
        });
      
        this.sliderNews = homeCategoryNews?.SliderNews || [];
      
        // Process Live Video Data
 
        //this.liveLink="https://pbc.furrera.ps/4dmedia/4dpal1/mpegts//embed.html";
        // this.liveLink = liveData?.LiveVideoUrl
        //   ? `https://www.youtube.com/embed/${liveData.LiveVideoUrl.split("=")[1]}?autoplay=1`
        //   : '';
      
        // Process Videos Data
        
        this.firstVideoLink = videosData[0] || null;
        this.videosData = videosData.filter(a=>a.VideoId != this.firstVideoLink.VideoId).slice(0, 4);
 
        // Process Advertisements
        this.advsTwo = advsData.find(a=>a.Order ===2) || null;
        this.advsThree = advsData.find(a=>a.Order ===3) || null;
      
       
      
      } catch (error) {
        console.error("Error:", error);
      }
      
     
    }


   
  
      
  }
    
    
    
 

