import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, forkJoin, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ImportantNews } from '../_models/ImportantNews';
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private exchangeRatesSubject = new BehaviorSubject<any>(null);
  private weatherDateSubject = new BehaviorSubject<any>(null);
 // private categoriesSubject = new BehaviorSubject<any[]>([]);
  private contactInfoSubject = new BehaviorSubject<ImportantNews>(null);
  private ads4InfoSubject = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

   // API Calls

  getExchangeRates(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/ExchangeRate/getRates`).pipe(
      catchError(this.handleError('getExchangeRates'))
    );
  }

  getWeatherDate(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Weather/getWetherDate`).pipe(
      catchError(this.handleError('getWeatherDate'))
    );
  }

  getCategoryAllData(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Category/getActiveCategories`).pipe(
      catchError(this.handleError('getCategoryAllData'))
    );
  }

  GetAllContactInfo(): Observable<any> {
    return this.http.get<ImportantNews>(`${environment.apiUrl}/ContactInfo/GetAllContactInfo`).pipe(
      catchError(this.handleError('GetAllContactInfo'))
    );
  }
   
  GetAllAds() : Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/Ads/GetAllAds');
  
  }
  GetAdsByOrder(order:number) : Observable<any>{
    return this.http.get<any>(environment.apiUrl + '/Ads/GetAdsByOrder/'+order);
  
  }

  setads4InfoData(data: any): void {
    this.ads4InfoSubject.next(data);
  }

 // Initialize Data
 initializeData(): void {
 
  forkJoin([
    this.getExchangeRates(),
    this.getWeatherDate(),
 //   this.getCategoryAllData(),
    this.GetAllContactInfo() // Include the new API call here
  ]).pipe(
    tap(([exchangeRates, weatherDate,   contactInfo]) => {
      this.exchangeRatesSubject.next(exchangeRates);
      this.weatherDateSubject.next(weatherDate);
//this.categoriesSubject.next(categories);
      this.contactInfoSubject.next(contactInfo);
    }),
    catchError(error => {
      console.error('Error initializing data:', error);
      // Handle or log the error as needed
      return []; // Return an empty array or handle error properly
    })
  ).subscribe();
}

// Error Handling
private handleError(operation: string) {
  return (error: any): Observable<never> => {
    console.error(`${operation} failed: ${error.message}`);
    // Optionally, transform the error or rethrow it
    throw new Error(`Failed to fetch ${operation}: ${error.message}`);
  };
}

// Observables to be used in components
get exchangeRates$(): Observable<any> {
  return this.exchangeRatesSubject.asObservable();
}

get weatherDate$(): Observable<any> {
  return this.weatherDateSubject.asObservable();
}
get sads4InfoData$(): any {
  return this.ads4InfoSubject.asObservable();
}

// get categories$(): Observable<any[]> {
//   return this.categoriesSubject.asObservable();
// }

get contactInfo$(): Observable<ImportantNews> {
  return this.contactInfoSubject.asObservable();
}
 
}
