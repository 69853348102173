<div class="case-details-img pt-100 pb-70 background-color-black">
    <div class="container align-items-center">
        <h2  class="col-white" style="    font-weight: bold; text-align: center; "> {{videoDate?.VideoTitle}}</h2>
        
        <br>
        <div class="row  ">
            <div class="col-md-9 col-sm-12">
                <div class="case-details-item">
                    <div class="video-container">
                         <iframe *ngIf="videoLink"   width="100%" height="100%" [src]="videoLink  | safe" frameborder="0" allowfullscreen></iframe>

    
                   
                         </div>
                </div>

                <div class="row  ">
                    <div class="col-10">
                        <div class="case-details-item">
                             
                                <div class="col-white" [innerHTML]="videoDate?.VideoContent" ></div>
        
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="col-md-3 col-sm-12">
                <h3 class="col-white bb1">شاهد ايضا</h3>
                <ng-container *ngFor="let videoItem of videosData">
                    <div
                        
                        style="margin-bottom: 7px"
                    >
                        <div class="video-card">
                                        <a
                                routerLink="/video-details/{{
                                    videoItem.VideoId
                                }}"
                                >
                                <div class="card-body" style="background: none">
                                    
                                    <div class="row">
                                        <div
                                            class="col-lg-5 col-md-5 col-xs-12 card-img-new"
                                        >
                                            <img
                                              
                                                class="img-fluid"
                                                [src]="prefixUrl+videoItem?.ImagePath"
                                                alt="Blog"
                                            />
                                        </div>
                                        <div
                                            class="col-lg-7 col-md-7 col-xs-12 card-text"
                                        >
                                            <h6
                                                class="news-title col-white"
                                                style="font-weight: bold"
                                            >
                                            {{ videoItem?.VideoTitle }}
                                            </h6>
    
                                             
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                   
                </ng-container>
            </div>
        </div>
      
        
    </div>
</div>